
import Animated from './animated'
import Static from './static'

export default
	props: block: Object

	data: ->
		speedMbps: null

	computed:
		component: ->
			return 'div' unless @speedMbps
			if @speedMbps < 1 then Static else Animated

	mounted: ->
		@getDownloadSpeed()

	methods:
		getDownloadSpeed: ->
			apiSpeed = navigator?.connection?.downlink
			if apiSpeed then @speedMbps = apiSpeed; return

			imageUrl = '/sampleimg.avif'
			imageSizeInBytes = 36378
			startTime = new Date().getTime()

			img = new Image()

			img.onload = =>
				endTime = new Date().getTime()
				durationInSeconds = (endTime - startTime) / 1000

				# Convert image size from bytes to megabits (1 byte = 8 bits, 1 Megabit = 1,000,000 bits)
				imageSizeInMegabits = (imageSizeInBytes * 8) / 1000000

				# Calculate download speed in Mbps
				@speedMbps = imageSizeInMegabits / durationInSeconds

			img.onerror = ->
				console.error 'Error loading the image.'

			img.src = "#{imageUrl}?cache_buster=#{new Date().getTime()}" # Prevent caching



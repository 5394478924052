
export default
	props: block: Object

	methods:
		getDesktopAsset: (entry) ->
			if entry?.desktopFallbackImage?.length then entry?.desktopFallbackImage else entry?.mobileFallbackImage

		getMobileAsset: (entry) ->
			if entry?.mobileFallbackImage?.length then entry?.mobileFallbackImage else entry?.desktopFallbackImage
